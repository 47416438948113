<template>
  <div>
    <TheNavbar/>

    <div class="container mt-4">

      <!-- TITLE -->
      <div class="row mb-3">
        <div class="col-md-12 animate fadeIn">
          <p class="overline text-primary-contrast mb-2">Welcome {{ currentUser.displayName || "Loading..." }}</p>
          <h1 class="fw-500 typography-headline-lg">Dashboard</h1>
        </div>
      </div>

      <!-- SHORTCUTS -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <p class="overline text-muted mb-0">
            <i class="material-icons-outlined mdc-18 text-muted me-1">&#xf060;</i>
            Shortcuts
          </p>
        </div>
        <div class="col-12 col-md-4 mb-3 animate fadeIn" style="--animation-index: 0.5s">
          <router-link :to="{name: 'Locations'}">
            <div class="card">
              <div class="card-body text-center">
                <i class="material-icons-outlined icon-wrap icon-wrap-primary mdc-28">&#xe55f;</i>
                <p class="subtitle-1 mt-2 mb-0">Restaurants locations</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-4 mb-3 animate fadeIn" style="--animation-index: 0.7s">
          <router-link :to="{name: 'Menu'}">
            <div class="card">
              <div class="card-body text-center">
                <i class="material-icons-outlined icon-wrap icon-wrap-primary mdc-28">&#xf206;</i>
                <p class="subtitle-1 mt-2 mb-0">Menu maker</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-4 mb-3 animate fadeIn" style="--animation-index: 0.9s">
          <router-link :to="{name: 'Account'}">
            <div class="card">
              <div class="card-body text-center">
                <i class="material-icons-outlined icon-wrap icon-wrap-primary mdc-28">&#xe853;</i>
                <p class="subtitle-1 mt-2 mb-0">Account</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <p class="overline text-muted mb-0">
            <i class="material-icons mdc-18 text-muted me-1">&#xf092;</i>
            Statistics
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card card-outline">
            <div class="card-body text-center">
              <p class="typography-headline-lg fw-500">{{ restaurantLocations.length || '0' }}</p>
              <p class="subtitle-1 text-muted mb-0">Restaurants locations</p>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="row mb-3">
              <div class="col-md-12">
                <div class="card card-outline">
                  <div class="card-header mt-3">
                    <h2 class="typography-headline-sm fw-500">Getting started</h2>
                    <p class="subtitle-1 text-muted">You're doing well 1 of 3 steps are completed!</p>
                  </div>
                  <div class="card-body">
                    <div class="list-group">
                      <router-link :to="{name: 'Settings'}" class="list-group-item list-group-item-action"
                                   aria-current="true">
                        <i class="material-icons text-success me-2">&#xe86c;</i>
                        Setup your restaurant general information
                      </router-link>
                      <router-link :to="{name: 'Locations'}" class="list-group-item list-group-item-action">
                        <i class="material-icons-outlined text-muted me-2">&#xe836;</i>
                        Create your first restaurant location
                      </router-link>
                      <router-link :to="{name: 'Menu'}" class="list-group-item list-group-item-action">
                        <i class="material-icons-outlined text-muted me-2">&#xe836;</i>
                        Create your digital menu
                      </router-link>
                      <router-link :to="{name: 'Home'}" class="list-group-item list-group-item-action disabled">
                        <i class="material-icons-outlined text-muted me-2">&#xf023;</i>
                        Connect payments
                        <span class="badge badge-secondary">Payments available soon</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->

    </div>

    <TheFooter/>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import TheFooter from "@/components/TheFooter";
import {db, firebaseAuth} from "@/firebase";
import {collection, getDocs} from "firebase/firestore";

export default {
  components: {TheFooter, TheNavbar},
  data() {
    return {
      currentUser: firebaseAuth.currentUser,
      restaurantLocations: [],
    }
  },
  mounted() {
    this.fetchRestaurantLocations()
  },
  methods: {
    fetchRestaurantLocations() {
      this.isLoading = true
      return getDocs(collection(db, 'users', firebaseAuth.currentUser.uid, 'restaurantLocations'))
          .then(querySnap => querySnap.docs.map(snap => ({id: snap.id, ...snap.data()})))
          .then(locations => {
            this.restaurantLocations = locations
          })
          .catch(console.error)
          .finally(() => this.isLoading = false)
    }
  }
}
</script>
