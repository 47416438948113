<template>
  <div></div>
  <!--  <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>The Footer</p>
          </div>
        </div>
      </div>
    </footer>-->
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped>

</style>